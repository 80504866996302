import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import 'lazysizes'

import './styles.scss'

const LazyImageWithLcpOptimisation = ({
  className,
  alt,
  dataSrc,
  dataSizes = 'auto',
  dataSrcSet,
}) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  const imageClasses = mounted
    ? classnames('lazyload', className).trim()
    : classnames('not-mounted', className).trim()

  const fallbackImageClasses = classnames('no-js-fallback', className)

  const loadingPlaceholderSvgImage =
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MDAiIGhlaWdodD0iMzAwIiB2aWV3Qm94PSIwIDAgNjAwIDMwMCI+CiAgPHJlY3QgZmlsbD0iI2UzZTNlMyIgZmlsbC1vcGFjaXR5PSIwIiB3aWR0aD0iNjAwIiBoZWlnaHQ9IjMwMCIvPgo8L3N2Zz4K'

  return (
    <>
      <img
        className={imageClasses}
        alt={alt}
        srcSet={loadingPlaceholderSvgImage}
        data-src={dataSrc}
        data-sizes={dataSizes}
        data-srcset={dataSrcSet || undefined}
      />
      <noscript>
        <img className={fallbackImageClasses} src={dataSrc} alt={alt} />
      </noscript>
    </>
  )
}

LazyImageWithLcpOptimisation.propTypes = {
  alt: PropTypes.string.isRequired,
  dataSizes: PropTypes.string,
  dataSrc: PropTypes.string.isRequired,
  dataSrcSet: PropTypes.string,
  className: PropTypes.string,
}

export default LazyImageWithLcpOptimisation
