import React from 'react';
import classNames from 'classnames';
import config from '../../../config';
import LazyImage from '../../atoms/LazyImage';
import Stars from './stars.svg';
import styles from './styles.module.scss';
export interface TrustPilotReviewCardProps {
  reviewText: string;
  photo: string;
  photoColour: string;
  name: string;
  colourVariant: string;
}
const TrustPilotReviewCard = ({
  reviewText,
  photo,
  name,
  colourVariant
}: TrustPilotReviewCardProps) => {
  return <article className={styles.card} data-sentry-component="TrustPilotReviewCard" data-sentry-source-file="index.tsx">
      <div className={styles.reviewerWrapper}>
        <LazyImage className={classNames(styles.photo, {
        [styles.photoGreyscale]: colourVariant === 'greyscale'
      })} alt={name} dataSrc={`${config.imgApiUrl}w=64/${photo}`} dataSrcSet={`${config.imgApiUrl}w=64/${photo} 1x, ${config.imgApiUrl},w=64,dpr=2/${photo} 2x`} data-sentry-element="LazyImage" data-sentry-source-file="index.tsx" />
        <div className={styles.reviewerInner}>
          <span className={styles.name}>{name}</span>
          <Stars className={styles.stars} data-sentry-element="Stars" data-sentry-source-file="index.tsx" />
        </div>
      </div>
      <blockquote className={styles.review}>{reviewText}</blockquote>
    </article>;
};
export default TrustPilotReviewCard;