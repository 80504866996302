import Review from '../../../types/Review'

const reviews: Review[] = [
  {
    reviewText:
      '“Very easy to use website, with a variety of properties, quick response times and very friendly and helpful staff.”',
    photo:
      'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/reviews/katie.png',
    name: 'Katie',
  },
  {
    reviewText:
      '“My first time looking for accommodation outside of uni halls, I was a bit nervous, but all my fears were wiped clean, this was such a smooth and hassle free process.”',
    photo:
      'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/reviews/olivia.png',
    name: 'Olivia',
  },
  {
    reviewText:
      '“I found a home in half a day and had the confirmation in hand. 10/10 would recommend.”',
    photo:
      'https://s3.eu-west-2.amazonaws.com/images.accommodationforstudents.com/website/home-page/reviews/hritika.png',
    name: 'Hritika',
  },
]

export default reviews
