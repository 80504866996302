import React from 'react';
import LazyImage from '../LazyImage';
import LazyImageWithLcpOptimisation from '../LazyImageWithLcpOptimisation';
interface PictureProps extends React.HTMLProps<HTMLPictureElement> {
  sources: {
    id: string;
    srcSet: string;
    media: string;
  }[];
  src: string;
  alt: string;
  lazyload?: boolean;
  optimiseLcp?: boolean;
}
const Picture = ({
  sources,
  src,
  alt,
  lazyload,
  optimiseLcp,
  ...props
}: PictureProps) => {
  let Image = null;
  if (optimiseLcp) {
    Image = <LazyImageWithLcpOptimisation dataSrc={src} alt={alt} />;
  } else if (lazyload) {
    Image = <LazyImage dataSrc={src} alt={alt} />;
  } else {
    Image = <img src={src} alt={alt} />;
  }
  return <picture {...props} data-sentry-element="picture" data-sentry-component="Picture" data-sentry-source-file="index.tsx">
      {sources.map(source => {
      return optimiseLcp || lazyload ? <source key={source.id} media={source.media} data-srcset={source.srcSet} /> : <source key={source.id} {...source} />;
    })}
      {Image}
    </picture>;
};
export default Picture;