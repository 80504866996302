import React from 'react';
import dynamic from 'next/dynamic';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import useMedia from '../../../hooks/useMedia';
import reviews from './reviews';
import Heading from '@afs/components/Heading';
import TrustpilotWidget from '../../molecules/TrustpilotWidget';
import TrustPilotReviewCard from '../../molecules/TrustPilotReviewCard';
import Button from '../../atoms/Button';
import Arrow from './arrow.svg';
import styles from './styles.module.scss';
const StudentReviews = dynamic(() => import('../../organisms/StudentReviews'), {
  ssr: false
});
export interface ReviewSectionProps {
  className?: string;
  initialIsMobile: boolean;
}
const ReviewSection = ({
  className,
  initialIsMobile
}: ReviewSectionProps) => {
  const [inViewRef, inView] = useInView({
    threshold: 0.01,
    initialInView: false,
    fallbackInView: false
  });
  const isMobileDevice = useMedia(['(max-width: 580px)'], [true], initialIsMobile);
  return <section ref={inViewRef} className={classNames(styles.section, className)} data-testid="reviews-section" data-sentry-component="ReviewSection" data-sentry-source-file="index.tsx">
      <Heading className={classNames(styles.heading, {
      [styles.headingTrustpilotNotLoaded]: !inView
    })} level={2} data-sentry-element="Heading" data-sentry-source-file="index.tsx">
        What students think of us
      </Heading>

      <TrustpilotWidget isMobileDevice={isMobileDevice} inView={inView} data-sentry-element="TrustpilotWidget" data-sentry-source-file="index.tsx" />

      <StudentReviews className={styles.studentReviews} colourVariant="colour" reviews={reviews} cardVariant={TrustPilotReviewCard} data-sentry-element="StudentReviews" data-sentry-source-file="index.tsx" />
      <Button className={styles.linkButton} href="https://uk.trustpilot.com/review/www.accommodationforstudents.com" target="_blank" rel="noopener" data-sentry-element="Button" data-sentry-source-file="index.tsx">
        See more reviews <Arrow className={styles.linkArrow} data-sentry-element="Arrow" data-sentry-source-file="index.tsx" />
      </Button>
    </section>;
};
export default ReviewSection;