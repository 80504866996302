import { useEffect } from 'react'
import { useIntercom } from 'react-use-intercom'
import useMedia from './useMedia'

const useIntercomMessenger = () => {
  const isMobile = useMedia(['(max-width: 768px)'], [true], false)

  const { boot, shutdown, show } = useIntercom()

  const openMessenger = () => {
    if (isMobile) {
      boot()
    }
    show()
  }

  const cleanup = () => {
    if (isMobile) {
      shutdown()
    }
  }

  useEffect(() => {
    return cleanup
  }, [isMobile])

  return { openMessenger }
}

export default useIntercomMessenger
