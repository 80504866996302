import config from '../config'
import { getConsistent } from './apiBase'
import { getOrAdd } from '../services/cacheService'

export const getLocations = (country = 'gb') => {
  const apiUrl =
    country === 'gb' ? `/api/locations` : `/api/locations/${country}`

  return getOrAdd(`locations:${country}`, async () => {
    const response = await getConsistent(`${config.apiBaseUrl}${apiUrl}`)

    if (response.success) {
      return response.data
    }

    return undefined
  })
}
