import React from 'react';
import classNames from 'classnames';
import Heading from '@afs/components/Heading';
import Link from '../../atoms/Link';
import './styles.scss';
interface Location {
  name: string;
  linkPath: string;
  propertyCount?: number;
}
export interface LocationLinksProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  heading: string;
  locations: Location[];
  shouldDisableLinksWithNoProperties?: boolean;
}
interface LocationLink extends React.HTMLAttributes<HTMLLIElement> {
  name: string;
  linkPath: string;
  propertyCount?: number;
  shouldDisableLinksWithNoProperties?: boolean;
}
const LocationLink = ({
  name,
  linkPath,
  propertyCount,
  shouldDisableLinksWithNoProperties,
  ...props
}: LocationLink) => {
  if (shouldDisableLinksWithNoProperties && propertyCount === 0) return null;
  return <li className="location-links__item" {...props} data-sentry-component="LocationLink" data-sentry-source-file="index.tsx">
      <Link className="location-links__location" href={`/${linkPath}`} title={name} prefetch="true" data-sentry-element="Link" data-sentry-source-file="index.tsx">
        {name}
      </Link>
    </li>;
};
const LocationLinks = ({
  className,
  locations,
  heading,
  shouldDisableLinksWithNoProperties,
  ...props
}: LocationLinksProps) => {
  const componentClasses = classNames('location-links', className);
  const numberOfLocations = locations.length;
  return <section className={componentClasses} {...props} data-sentry-component="LocationLinks" data-sentry-source-file="index.tsx">
      <div className="location-links__inner">
        <Heading className="location-links__heading" level={2} data-sentry-element="Heading" data-sentry-source-file="index.tsx">
          {heading}
        </Heading>
        <ul className={classNames('location-links__list', {
        'location-links__list--1-col-flex': numberOfLocations === 1,
        'location-links__list--2-col-flex': numberOfLocations === 2,
        'location-links__list--2-col': numberOfLocations === 4,
        'location-links__list--3-col-flex': numberOfLocations === 3,
        'location-links__list--3-col': numberOfLocations === 5 || numberOfLocations === 6 || numberOfLocations === 9,
        'location-links__list--4-col': numberOfLocations === 7 || numberOfLocations === 8 || numberOfLocations >= 10 && numberOfLocations <= 12,
        'location-links__list--5-col': numberOfLocations >= 13 && numberOfLocations <= 15 || numberOfLocations >= 19 && numberOfLocations <= 21
      })}>
          {locations.map(loc => <LocationLink key={loc.name} linkPath={loc.linkPath} name={loc.name} propertyCount={loc.propertyCount} shouldDisableLinksWithNoProperties={shouldDisableLinksWithNoProperties} />)}
        </ul>
      </div>
    </section>;
};
export default LocationLinks;