import React from 'react';
import classNames from 'classnames';
import Faq from '../../molecules/Faq';
import styles from './styles.module.scss';
export interface FaqSectionFaq {
  question: string;
  answer: React.ReactNode;
}
export interface FaqSectionProps {
  className?: string;
  heading: React.ReactNode;
  subheading?: React.ReactNode;
  questions: FaqSectionFaq[];
}
const FaqSection = ({
  className,
  heading,
  subheading,
  questions
}: FaqSectionProps) => {
  return <section className={classNames(styles.faqSection, className)} itemScope itemType="http://schema.org/FAQPage" data-sentry-component="FaqSection" data-sentry-source-file="index.tsx">
      {subheading && <div className={styles.subheadingWrapper} data-testid="faq-subheading">
          {subheading}
        </div>}

      <div className={styles.headingWrapper} data-testid="faq-heading">
        {heading}
      </div>

      <div className={styles.faqWrapper}>
        {questions.map((question, index) => <Faq index={index} key={question.question} className={styles.faq} question={question.question} answer={question.answer} />)}
      </div>
    </section>;
};
export default FaqSection;