import React, { forwardRef } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Script from 'next/script';
interface TrustPilotWidgetProps {
  isMobile: boolean;
}
const MainTrustpilotWidget = forwardRef<HTMLDivElement, TrustPilotWidgetProps>(({
  isMobile
}, ref) => {
  return <>
        <Script src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" />

        <div key={`trustpilot-${isMobile}`} ref={ref} className={classNames('trustpilot-widget', styles.trustPilotWidget)} data-locale="en-GB" data-template-id={isMobile ? '5419b732fbfb950b10de65e5' : '5419b6ffb0d04a076446a9af'} data-businessunit-id="4bf32e3400006400050d12e3" data-style-height="20px" data-style-width="100%" data-theme="light" data-testid={isMobile ? 'mobile-trustpilot-widget' : 'trustpilot-widget'}>
          <a href="https://uk.trustpilot.com/review/www.accommodationforstudents.com" target="_blank" rel="noopener noreferrer">
            Trustpilot
          </a>
        </div>
      </>;
});
export default MainTrustpilotWidget;