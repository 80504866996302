import React, { useState } from 'react';
import classNames from 'classnames';
import Heading from '@afs/components/Heading';
import styles from './styles.module.scss';
export interface FaqProps {
  className?: string;
  index: number;
  question: string;
  answer: React.ReactNode;
}
const Faq = ({
  className,
  question,
  answer,
  index
}: FaqProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  return <div className={className} itemScope itemProp="mainEntity" itemType="https://schema.org/Question" data-sentry-component="Faq" data-sentry-source-file="index.tsx">
      <button data-testid={`button-${index}`} className={styles.button} itemProp="name" aria-controls={`faq-answer-${index}`} aria-expanded={!isCollapsed} onClick={() => setIsCollapsed(prevState => !prevState)}>
        <Heading className={styles.question} level={4} data-testid="faq-question" data-sentry-element="Heading" data-sentry-source-file="index.tsx">
          {question}
        </Heading>
        <span className={classNames(styles.icon, {
        [styles.expandedAnswerIcon]: !isCollapsed
      })}></span>
      </button>
      <div id={`faq-answer-${index}`} className={classNames(styles.answer, {
      [styles.answerCollapsed]: isCollapsed
    })} itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer" data-testid="faq-answer">
        <div className={styles.text} itemProp="text">
          {answer}
        </div>
      </div>
    </div>;
};
export default Faq;